/* webpackMode: "lazy-once" */
/* webpackChunkName: "process" */
import * as processShim from 'process/browser';
// =============================================================================
// IMPORTANT MONA OVERRIDE: PROCESS FOR BROWSER
// =============================================================================

function isNotEmpty<T extends any>(value: T): value is NonNullable<T> {
    return value !== undefined || value !== null || value !== 'undefined' || value !== 'null';
}

try {
    process?.platform;
} catch (error) {
    globalThis.process = processShim;

    if (typeof window?.electron?.process !== 'undefined') {
        const sourceObj = window.electron.process.env;
        const targetObj = process.env;

        Object.keys(sourceObj).forEach(key => {
            const sourceValue = sourceObj[key];
            if (isNotEmpty(sourceValue)) {
                targetObj[key] = sourceValue;
            }
        });

        (process as any).platform = window.electron.process.platform;
        (process as any).version = window.electron.process.version;
        (process as any).versions = window.electron.process.versions;
    }
}
